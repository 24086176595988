<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='but m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "settings" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.cards.c9bf5ecc') }}</div>
        </div>
        <skeleton-loader-component class='mt-24'
          :loading='!cards'>
          <div v-if='cards && cards.length'>
            <card-element class='fai'
              v-for='card in cards'
              :key='card.id'>
              <credit-card-component class='oam'
                :card='card'/>
              <div class='flex justify-end'>
                <spinner-element class='text-gray-500'
                  v-if='loadings[card.id]'/>
                <div class='flex cursor-pointer'
                  v-if='!loadings[card.id]'
                  @click='removeCard(card)'>
                  <i class='icon text-gray-700 hover:text-red'>remove_circle_outline</i>
                </div>
              </div>
            </card-element>
            <new-card-section/>
          </div>
          <div v-else>
            <new-card-section/>
            <div class='my-48 text-center text-gray-500'>{{ $t('pages.cards.e6773354') }}</div>
            <img class='mok m-auto'
              src='images/94fb.png'>
          </div>
        </skeleton-loader-component>
      </div>
    </div>
    <snackbar-element
      ref='errorSnackbar'
      :duration='5000'>
      <div class='flex p-16 text-red'>
        <i class='icon mr-16'>error</i>
        <div v-if='error === "unknown"'>{{ $t('pages.cards.079fb7dd') }}</div>
      </div>
    </snackbar-element>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import CreditCard from '@/components/credit-card'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'
  import store from '@/store'
  import NewCard from './new-card'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'credit-card-component': CreditCard,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation,
      'new-card-section': NewCard
    },

    data: () => ({
      error: '',
      loadings: {}
    }),

    computed: {
      cards () {
        return this.$store.state.me.cards
      }
    },

    mounted () {
      this.$store.dispatch('me/fetchCards')
    },

    methods: {
      async removeCard (card) {
        try {
          this.loadings = {
            ...this.loadings,
            [card.id]: true
          }

          let id = card.id
          await this.$store.dispatch('me/removeCard', { id })
        } catch {
          this.error = 'unknown'
          this.$refs.errorSnackbar.show()
        } finally {
          this.loadings = {
            ...this.loadings,
            [card.id]: false
          }
        }
      }
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .but {
    max-width: 480px;
  }

  .fai {
    &:not(:first-child) {
      @apply mt-16;
    }
  }

  .oam::v-deep .card {
    @apply
      p-0
      shadow-none;
  }

  .mok {
    width: 240px;
  }
</style>
